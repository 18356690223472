$mb: 1023px;
$desk: 1024px;

*{
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  text-decoration: none !important;
  line-height: normal;
  font-weight: 300;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color: #2F3338;
  font-weight: 300;
  font-family: 'Apercu Pro' !important;
  font-size: 1.6rem;
  line-height: normal;
  background-color: #fff;
}

html {
  font-size: 0.69444vw;
}

.container {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
  width: 100%;
}

.sc__full {
  min-height: 100svh;
}

.sc__login {
  .container {
    padding: 10rem 4rem 7.5rem;

    @media screen and (max-width: $mb){
      padding: 9rem 2rem;
    }
  }
}

.error__message {
  font-size: 1.4rem;
  line-height: 140%;
  text-transform: lowercase;
  color: #A44F29;

  @media screen and (max-width: $mb){
    font-size: 1.2rem;
  }
}

.login__box {
  width: 75rem;
  min-height: 72.5rem;
  background: #6A6A6A;
  color: #fff;
  padding: 12rem 9rem 5rem;

  @media screen and (max-width: $mb){
    width: 100%;
    padding: 5.4rem 3.2rem 6rem;
    min-height: 0;
  }

  .error__message {
    color: #ff0000;
  }

  &-hd {
    margin-bottom: 4rem;
  }

  &-title {
    margin-bottom: 1.2rem;
    font-size: 2.8rem;
    letter-spacing: 0.084rem;
    text-transform: uppercase;

    @media screen and (max-width: $mb){
      font-size: 2.4rem;
      letter-spacing: 0.072rem;
    }
  }

  &-subtitle {
    font-size: 1.4rem;
    letter-spacing: 0.042rem;
    text-transform: uppercase;

    @media screen and (max-width: $mb){
      font-size: 1.2rem;
      letter-spacing: 0.036rem;
    }
  }
}

.login__form {
  max-width: 27rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10.2rem;

  @media screen and (max-width: $mb){
    margin-bottom: 7rem;
  }

  &-hint {
    margin-top: 2.5rem;
    max-width: 30rem;
    margin-right: auto;
    margin-left: auto;
    color: #FFF;
    text-align: center;
    font-size: 1.6rem;
    line-height: 140%;

    @media screen and (max-width: $mb){
      font-size: 1.4rem;
      line-height: 121%;
      margin-top: 2rem;
    }
  }

  .form__field {
    input, textarea {
      border-bottom-color: #fff;
      color: #fff;
      background: transparent;

      &::-webkit-input-placeholder {
        color: #fff;
      }

      &:-moz-placeholder {
        color: #fff;
      }

      &::-moz-placeholder {
        color: #fff;
      }

      &:-ms-input-placeholder {
        color: #fff;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: #fff;
      -webkit-box-shadow: 0 0 0 1000px #6A6A6A inset !important;
      transition: background-color 5000s ease-in-out 0s;
    }

    &.has-error {
      input, textarea {
        border-bottom-color: #ff0000;
        color: #ff0000;

        &::-webkit-input-placeholder {
          color: #ff0000;
        }

        &:-moz-placeholder {
          color: #ff0000;
        }

        &::-moz-placeholder {
          color: #ff0000;
        }

        &:-ms-input-placeholder {
          color: #ff0000;
        }
      }
    }
  }
}

.form--loading {
  position: relative;

  .form__list, .btn, .code__form-field, .code__form-title, .code__form-descr{
    opacity: 0.3;
  }

  &:before{
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    position: absolute;
  }

  &:after {
    content: '';
    background: url("../img/loader.svg") no-repeat center;
    background-size: contain;
    width: 9.6rem;
    height: 9.6rem;
    left: 50%;
    top: 50%;
    position: absolute;
    margin-left: -4.8rem;
    margin-top: -4.8rem;
  }
}

.conf__bottom-btns {
  @media screen and (max-width: $mb){
    margin-top: 6rem;
    width: 100%;
  }

  &.disabled {
    position: relative;

    .conf__bottom-btn {
      opacity: 0.5;
    }

    &:before{
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 10;
      position: absolute;
    }

    &:after {
      content: '';
      background: url("../img/loader.svg") no-repeat center;
      background-size: contain;
      width: 6.4rem;
      height: 6.4rem;
      left: 50%;
      top: 50%;
      position: absolute;
      margin-left: -3.2rem;
      margin-top: -3.2rem;
    }
  }
}

.form__it {
  &:not(:last-child){
    margin-bottom: 1rem;
  }
}

.form__field {
  input, textarea {
    height: 2.6rem;
    padding: 0;
    display: block;
    width: 100%;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    font-size: 1.4rem;
    letter-spacing: 0.042rem;
    text-transform: uppercase;

    @media screen and (max-width: $mb){
      font-size: 1.2rem;
    }
  }

  textarea {
    resize: none;
    height: 9rem;
    padding-top: 0.8rem;
  }
}

.form__list {
  margin-bottom: 3rem;
}

.btn {
  cursor: pointer;
  height: 4.4rem;
  min-width: 21rem;
  font-size: 1.9rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background: transparent;
  border-radius: 0;
  outline: none !important;
  box-shadow: none !important;
  font-weight: 300;

  @media screen and (max-width: $mb){
    height: 4.2rem;
    font-size: 1.6rem;
    letter-spacing: 0.08rem;
  }

  &--white {
    border: 1px solid #FFF;
    color: #FFF;

    &:not([disabled]):hover {
      color: #2F3338;
      background-color: #fff;
    }
  }

  &--dark {
    border: 1px solid #2F3338;
    color: #2F3338;
    cursor: pointer;

    &:not([disabled]):hover {
      color: #fff !important;
      background-color: #2F3338;
    }
  }

  &--small {
    height: 4.2rem;
    font-size: 1.6rem;
    letter-spacing: normal;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;

  .container {
    padding: 4rem;
  }
}

.sc__login {
  position: relative;

  .container {
    position: relative;
    z-index: 5;
  }
}

.decor {
  position: absolute;

  &--login-1 {
    width: 36.7rem;
    right: 0;
    top: 0;
    bottom: 0;

    @media screen and (min-width: $desk){
      background: url("../img/login-bg-1.jpg") no-repeat center;
      background-size: cover;
    }

    @media screen and (max-width: $mb){
      background: url("../img/login-bg-1-sm.jpg") no-repeat center;
      background-size: cover;
      width: 50vw;
    }
  }

  &--login-2 {
    background: url("../img/login-bg-2.jpg") no-repeat center;
    background-size: cover;
    width: 108.4rem;
    left: 0;
    bottom: 0;
    height: 57.7rem;

    @media screen and (min-width: $desk){
      background: url("../img/login-bg-2.jpg") no-repeat center;
      background-size: cover;
    }

    @media screen and (max-width: $mb){
      background: url("../img/login-bg-2-sm.jpg") no-repeat center;
      background-size: cover;
      width: 27.9rem;
      height: 29.1rem;
      bottom: 0.5rem;
    }
  }

  &--login-3 {
    background: #DBDBDB;
    width: 16rem;
    height: 23.3rem;
    left: 0;
    bottom: 0;
    z-index: 6;

    @media screen and (max-width: $mb){
      width: 9.7rem;
      height: 12.6rem;
    }
  }
}

.footer {
  position: absolute;
  z-index: 6;
  bottom: 0;
  right: 0;
  height: 9.6rem;
  padding: 0 11.2rem;
  background: #DBDBDB;

  @media screen and (max-width: $mb){
    flex-wrap: wrap;
    height: auto;
    padding: 2rem 1.2rem 1.6rem 2rem;
    left: 11.7rem;
    z-index: 20;
    background: #CCC
  }

  &__it {
    &:not(:last-child){
      margin-right: 12rem;

      @media screen and (max-width: $mb){
        margin-right: 0;
        margin-bottom: 0.5rem;
      }
    }

    color: #2F3338;
    font-size: 1.6rem;
    font-weight: 300;
    text-transform: uppercase;

    @media screen and (max-width: $mb){
      font-size: 1rem;
    }

    a {
      color: #2F3338 !important;

      &:hover {
        color: #A44F29 !important;
      }
    }
  }
}

.sc__main {
  position: relative;
  min-height: 100svh;
}

.sc__main-left {
  position: fixed;
  width: 32rem;
  left: 0;
  top: 0;
  background: #DBDBDB;
  height: 100svh;
  display: flex;
  flex-direction: column;
  padding-top: 8.4rem;

  @media screen and (max-width: $mb){
    position: absolute;
    width: 100%;
    height: auto;
    background: transparent;
  }
}

.sc__main-left-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-left: 4rem;
  padding-right: 4rem;

  @media screen and (max-width: $mb){
    opacity: 0;
    visibility: hidden;
    background: #D9D9D9;
    height: auto;
    z-index: 40;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    will-change: opacity, visibility;
    transition: opacity 0.2s ease, visibility 0.2s ease;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #2F3338;
  }

}

.sc__main-footer {
  padding: 3.2rem 2.4rem;
  width: 100%;

  @media screen and (max-width: $mb){
    padding: 2rem 2rem 2.4rem;
  }
}

.left__tab {
  &:not(.active){
    display: none !important;
  }
}

.left__form {
  margin-top: 2.4rem;
}

.left__nav {
  padding-top: 9rem;
  user-select: none;

  @media screen and (max-width: $mb){
    padding-top: 2rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: block;
      color: #2F3338;
      font-size: 1.6rem;
      font-weight: 300;
      text-transform: uppercase;

      &.active {
        & > a {
          color: #A44F29 !important;

          &:hover {
            color: #A44F29 !important;
          }
        }
      }

      &.left__nav--indent {
        padding-top: 2.4rem;

        a {
          color: #A44F29 !important;
        }
      }

      &:not(:last-child){
        margin-bottom: 3rem;
      }

      a {
        color: #2F3338 !important;
        will-change: color;
        cursor: pointer;
        transition: 0.2s color ease;

        &:hover {
          color: #6A6A6A !important;
        }
      }

      ul {
        padding-left: 1.9rem;

        li {
          margin-top: 1.6rem;
          margin-bottom: 0 !important;
          font-size: 1.4rem;
        }
      }
    }
  }
}

.side__phone {
  color: #2F3338;
  font-size: 1.4rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 0.8rem;

  a {
    color: #2F3338 !important;

    &:hover {
      color: #A44F29 !important;
    }
  }
}

.side__address {
  color: #2F3338;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 140%;
  text-transform: uppercase;

  a {
    color: #2F3338 !important;

    &:hover {
      color: #A44F29 !important;
    }
  }
}

.side__social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.7rem;

  @media screen and (max-width: $mb){
    margin-top: 0.8rem;
  }

  a {
    width: 2.4rem;
    height: 2.4rem;
    will-change: opacity;
    transition: 0.2s opacity ease;

    &:hover {
      opacity: 0.7;
    }

    &:not(:last-child){
      margin-right: 0.8rem;
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.nav__bars {
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  right: 3.8rem;
  top: 4rem;
  cursor: pointer;
  z-index: 5;

  @media screen and (max-width: $mb){
    right: 2rem;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.left__form-title {
  margin-bottom: 1.2rem;
  color: #2F3338;
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
}

.left__form {
  .form__it {
    &:not(:last-child){
      margin-bottom: 0.8rem;
    }
  }

  .form__field {
    input {
      font-size: 1.6rem;
      height: 3rem;
      background-color: transparent;
      border-bottom-color: #B2B2B2;

      &::-webkit-input-placeholder {
        color: #B2B2B2;
        font-size: 1.2rem;
      }

      &:-moz-placeholder {
        color: #B2B2B2;
        font-size: 1.2rem;
      }

      &::-moz-placeholder {
        color: #B2B2B2;
        font-size: 1.2rem;
      }

      &:-ms-input-placeholder {
        color: #B2B2B2;
        font-size: 1.2rem;
      }

      &:focus {
        border-bottom-color: #2F3338;
      }
    }
  }
}

.form__lbl {
  color: #2F3338;
  font-size: 1.2rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 0.5rem;

  span {
    color: #A44F29;
  }
}

.checkbox__wrap {
  label {
    position: relative;
    margin-bottom: 0;
    font-weight: 300;
    display: flex;
    align-items: flex-start;

    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;

      &:checked {
        & ~ .checkbox__decor {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}

.form__it--actions {
  padding-top: 0.8rem;
}

.checkbox__decor {
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  margin-right: 1.4rem;
  border: 1px solid #B2B2B2;
  position: relative;

  @media screen and (max-width: $mb){
    margin-top: 0;
    margin-left: 0;
    margin-right: 0.6rem;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../img/checked.svg") no-repeat center;
    background-size: contain;
    left: 0;
    top: 0;
    opacity: 0;
    will-change: opacity;
    transition: 0.2s ease opacity;
  }
}

.checkbox__title {
  color: #2F3338;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 120%;

  a {
    font-weight: 400;
    cursor: pointer;
    border-bottom: 1px solid #2F3338;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.form__it--indent {
  margin-top: 2.4rem;
}

.sc__main {
  .container {
    padding: 4.8rem 7.6rem 4.8rem 39.6rem;

    &.conf__wrap {
      padding: 0 0 0 32rem;

      @media screen and (max-width: $mb){
        padding-right: 2rem;
        padding-left: 2rem;
      }
    }

    @media screen and (max-width: $mb){
      padding: 9.6rem 2rem 4.4rem;
    }
  }
}

.conf__tb-head {
  padding-right: 1rem;
}

.proj__toggle-box {
  position: relative;
}

.proj__dropdown-it{
  cursor: pointer;
  will-change: opacity;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }

  &:not(:last-child){
    margin-bottom: 1.2rem;
  }

  &.active {
    .proj__dropdown-checkbox{
      svg {
        path.checked {
          opacity: 1;
        }
      }
    }
  }
}

.proj__dropdown-checkbox {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  margin-left: 0.8rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;

    path.checked {
      opacity: 0;
    }
  }
}

.proj__dropdown-it-name {
  color: #6A6A6A;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  border-bottom: 1px solid #6A6A6A;

  @media screen and (max-width: $mb){
    font-size: 1.4rem;
  }
}

.proj__dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  border: 1px solid #6A6A6A;
  background: #DBDBDB;
  padding: 1.6rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;

  @media screen and (max-width: $mb){
    top: 100%;
    margin-top: -1px;
    left: 0.4rem;
  }

  .btn {
    height: 3.2rem;
    font-size: 1.6rem;
    padding-top: 0.6rem;
    margin-top: 2rem;
  }
}

.proj__toggle-box {
  &.active {
    .proj__dropdown {
      opacity: 1;
      visibility: visible;
    }
  }
}

.proj__toggle {
  color: #2F3338;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  height: 2.6rem;
  border-bottom: 1px solid #2F3338;
  cursor: pointer;
  white-space: nowrap;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.conf__tb-actions {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 5;
  
  &-btn {
    cursor: pointer;
  }

  @media screen and (max-width: $mb){
    position: relative;
    height: auto;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8rem;
  }
}

.conf__tb-actions-btn {
  width: 5.5rem;
  background: #6A6A6A;
  height: 100%;
  will-change: opacity;
  transition: opacity 0.2s ease;

  @media screen and (max-width: $mb){
    width: calc(50% - 1rem);
    height: 3rem;

    .conf__action-ct {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
    }

  }

  &:hover {
    opacity: 0.7;
  }

  &:not(:last-child){
    margin-right: 0.2rem;

    @media screen and (max-width: $mb){
      margin-right: 0;
    }
  }
}

.conf__icon-2 {
  width: 1.4rem;
  height: 1.5rem;
  min-width: 1.4rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0.4rem;

  @media screen and (max-width: $mb){
    margin-left: 0.8rem;
    margin-right: 0;
    margin-bottom: 0;
    width: 1.6rem;
    height: 1.7rem;
    min-width: 1.6rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

.icon__plus {
  width: 0.4rem;
  height: 0.4rem;
  min-width: 0.4rem;
  margin-right: 0.4rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

.conf__form-it {
  &:not(:last-child){
    margin-bottom: 2.7rem;
  }

  .range-slider {
    height: 1px;
    background-color: #B2B2B2;
  }

  .range-slider {
    .range-slider__range {
      background: #2F3338;
      height: 1px;
      top: 0;
      transform: none !important;
    }

    .range-slider__thumb {
      width: 2rem;
      height: 2rem;
      background: #D9D9D9;
      border: 1px solid #2F3338;
      cursor: pointer;
    }
  }

  &.range--tooltips {
    .range-slider {
      .range-slider__thumb {
        width: 4.2rem;
        height: 2.4rem;
        border-radius: 1.2rem;

        &:before {
          content: attr(aria-valuenow);
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 1.3rem;
          color: #2F3338;
          font-weight: 300;
        }
      }
    }
  }
}

.conf__form-it-lbl {
  color: #2F3338;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 2.2rem;
}

.conf__bottom-choose {
  min-width: 14rem;
  justify-content: flex-end;

  @media screen and (max-width: $mb){
    flex-direction: row-reverse;
    min-width: 0;
  }
}

.conf__tb-actions-btn--remove {
  background: #95BD9B !important;
}

.icon__minus {
  width: 0.4rem;
  height: 0.2rem;
  min-width: 0.4rem;
  margin-right: 0.4rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

.conf__icon-descr {
  color: #FFF;
  text-align: center;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1;

  @media screen and (max-width: $mb){
    margin-right: auto;
    margin-left: auto;
    font-size: 1rem;

    .text-left {
      text-align: center !important;
    }
  }
}

.conf__icon-1 {
  width: 1.6rem;
  margin-right: auto;
  margin-left: auto;
  height: 1.6rem;
  min-width: 1.6rem;
  margin-bottom: 0.5rem;

  @media screen and (max-width: $mb){
    margin-left: 0.8rem;
    margin-right: 0;
    margin-bottom: 0;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible !important;
  }
}

.conf__labels-it {
  &:not(:last-child){
    margin-right: 2rem;
  }
}

.conf__labels {
  padding: 6.8rem 2rem 2.6rem;

  @media screen and (max-width: $mb){
    padding: 0;
    margin-bottom: 2.2rem;
  }
}

.conf__labels-it-span {
  color: #000;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
  padding-top: 0.25rem;
}

.conf__labels-it-sq {
  width: 1.6rem;
  height: 1.6rem;
  min-width: 1.6rem;
  margin-right: 0.6rem;
  border: 1px solid #2F3338;
  background: #DBDBDB;
}

.conf__tb {
  flex: 1 1;
  padding-left: 2rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media screen and (max-width: $mb){
    padding-left: 0;
    padding-right: 0;
  }
}

.conf__tb-wrap {
  display: flex;
  flex-direction: column;
}

.conf__tb{
  position: relative;
  padding-bottom: 1rem;

  &.disabled {
    .conf__tb-head, .conf__tb-body {
      opacity: 0.5;
    }

    &:before{
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 10;
      position: absolute;
    }

    &:after {
      content: '';
      background: url("../img/loader-dark.svg") no-repeat center;
      background-size: contain;
      width: 9.6rem;
      height: 9.6rem;
      left: 50%;
      top: 50%;
      position: absolute;
      margin-left: -4.8rem;
      margin-top: -4.8rem;
    }
  }
}

.modal__loader {
  height: 30rem;
}

.modal__loader-icon {
  width: 9.6rem;

  img {
    display: block;
    width: 100%;
  }
}

.video__wrap-box {
  padding-right: 2rem;
}

.video__group-title {
  text-transform: uppercase;
  margin-bottom: 2rem;
  color: #FFF;
  font-size: 2rem;
  font-weight: 400;
  line-height: normal;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
}

.modal--video {
  .modal__title--large {
    margin-bottom: 4rem;

    @media screen and (max-width: $mb){
      margin-bottom: 3rem;
    }
  }
}

.video__group {
  &:not(:last-child){
    margin-bottom: 4.5rem;
  }
}

.video__wrap{
  margin-right: -2rem;
}

.video__group {
  padding-right: 2rem;
}

.video__scroll {
  @media screen and (min-width: $desk){
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 52rem;

    &::-webkit-scrollbar {
      width: 0.2rem;
    }

    &::-webkit-scrollbar-track {
      background: rgba(#B2B2B2, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background: #B2B2B2;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #B2B2B2;
    }
  }
}

.video__grid {
  display: grid;
  grid-row-gap: 4rem;
  grid-column-gap: 7.4rem;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: $mb){
    grid-row-gap: 3rem;
    grid-column-gap: 0;
    grid-template-columns: repeat(1, 1fr);
  }
}

.video__load {
  width: 1.4rem;
  height: 1.3rem;
  cursor: pointer;
  position: relative;
  min-width: 1.4rem;
  margin-left: 1.2rem;

  @media screen and (max-width: $mb){
    &:before {
      content: '';
      left: -0.5rem;
      right: -0.5rem;
      bottom: -0.5rem;
      top: -0.5rem;
      position: absolute;
    }
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.video__back {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  margin-right: 1.4rem;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    left: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    top: -0.5rem;
    position: absolute;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.video__player {
  margin-bottom: 1.2rem;
}

.video__player {
  padding-top: 56.25%;
  position: relative;
  height: 0;
}

.video__player > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto !important;
  height: auto !important;
}

.video__it-prev {
  cursor: pointer;
  position: relative;
  margin-bottom: 1.2rem;

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.10);
    will-change: background-color;
    transition: background-color 0.2s ease;
    cursor: pointer;
  }

  img {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  &:hover {
    &:before {
      background: rgba(0, 0, 0, 0.20);
    }
  }
}

.video__it-title {
  color: #FFF;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1;
  margin-right: auto;
  padding-top: 0.1rem;
}

.video__play {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -2.5rem;
  margin-top: -2.5rem;
  position: absolute;
  z-index: 3;
  border: 0.2rem solid #FFF;
  background: rgba(255, 255, 255, 0.13);
  backdrop-filter: blur(0.15rem);

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1024px){
  .conf__tb-body {
    overflow-y: auto;
    flex: 1 1;
    padding-right: 1rem;

    &::-webkit-scrollbar {
      width: 2px;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #2F3338;
    }
  }
}

.u-mt--20 {
  margin-top: 2rem;
}

.conf__tb-col {
  flex: 0 0 100%;
  max-width: 100%;

  @media screen and (max-width: 1023px){
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child){
      margin-bottom: 0.8rem;
    }

    &:first-child {
      text-align: left;
      padding-bottom: 1rem;

      &:before {
        display: none !important;
      }
    }

    &--last {
      justify-content: flex-start !important;
      padding-top: 1rem;

      &:before {
        display: none !important;
      }
    }

    &:before {
      content: attr(data-title);
      text-transform: uppercase;
      color: #000;
      text-align: center;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
}

@media screen and (min-width: 1024px){
  .conf__tb-col {
    &:nth-child(1){
      flex: 0 0 15%;
      max-width: 15%;
    }

    &:nth-child(2){
      flex: 0 0 8%;
      max-width: 8%;
    }

    &:nth-child(3){
      flex: 0 0 8%;
      max-width: 8%;
    }

    &:nth-child(4){
      flex: 0 0 8%;
      max-width: 8%;
    }

    &:nth-child(5){
      flex: 0 0 8%;
      max-width: 8%;
    }

    &:nth-child(6){
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(7){
      flex: 0 0 10%;
      max-width: 10%;
    }

    &:nth-child(8){
      flex: 0 0 11%;
      max-width: 11%;
    }

    &:nth-child(9){
      flex: 0 0 8%;
      max-width: 8%;
    }

    &:nth-child(10){
      flex: 0 0 14%;
      max-width: 14%;
    }
  }
}

.conf__tb-head {
  margin-bottom: 1.2rem;
}

.conf__tb-row {
  padding-right: 11.2rem;
  position: relative;

  @media screen and (max-width: $mb){
    padding-right: 0;
  }
}

.conf__bottom-link {
  color: #FFF;
  text-align: center;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.06rem;
  text-transform: uppercase;

  @media screen and (max-width: $mb){
    position: absolute;
    left: 2rem;

    &--watch {
      top: 5rem;
      font-size: 1.15rem;
    }

    &--remove {
      top: 7.2rem;
      font-size: 1.15rem;
    }
  }

  a {
    color: #fff !important;
    border-bottom: 1px solid #fff;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.conf__bottom-btn {
  cursor: pointer;
  height: 3.2rem;
  border: 1px solid #B2B2B2;
  will-change: border-color;
  transition: border-color 0.2s ease;
  color: #FFF;
  text-align: center;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.1rem;
  text-transform: uppercase;
  min-width: 11rem;
  padding-top: 0.2rem;

  &:not(:last-child){
    margin-right: 1rem;
  }

  &:hover {
    border: 1px solid #ffffff;
  }

  @media screen and (max-width: $mb){
    min-width: 0;
    flex-grow: 1;
    padding-right: 4rem;
    padding-left: 4rem;
    white-space: nowrap;
    font-size: 1.2rem;
    height: 3rem;
  }
}

.conf__bottom-add {
  cursor: pointer;

  @media screen and (max-width: $mb){
    position: absolute;
    right: 2rem;
    flex-direction: row-reverse;
    text-align: right;
    top: 6rem;
  }

  &.active {
    .conf__bottom-add-checkbox {
      svg {
        path.checked {
          opacity: 1;
        }
      }
    }
  }
}

.pres__img {
  img {
    display: block;
    width: 100%;
  }

  &:not(:last-child){
    margin-bottom: 1rem;

    @media screen and (max-width: $mb){
      margin-bottom: 0.4rem;
    }
  }
}

.conf__bottom-add-title {
  color: #FFF;
  text-align: center;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  margin-left: 1rem;

  @media screen and (max-width: $mb){
    margin-right: 1rem;
    margin-left: 0;
    font-size: 1.15rem;
    text-align: right;
  }
}

.modal--pres {
  .modal__content-box-bl {
    padding: 0 !important;
  }

  .modal__close {
    path {
      fill: #2F3338;
    }
  }
}

.modal--order {
  .modal__content-box {
    max-width: 40rem;
    background-color: #9b9b9b;
  }

  .error__message {
    color: #ff0000;
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  .checkbox__decor {
    margin-top: 0;
  }

  .checkbox__decor:before {
    background-image: url("../img/checked-wh.svg");
  }

  .checkbox__title {
    color: #FFF;
  }

  .modal__content-box-bl {
    padding: 3rem;
  }

  .login__form {
    max-width: 100%;
    margin-bottom: 0;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: #fff;
      -webkit-box-shadow: 0 0 0 1000px #9b9b9b inset !important;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.conf__bottom-add-checkbox {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;

  svg {
    width: 100%;
    display: block;
    height: 100%;
    overflow: visible !important;

    path.checked {
      opacity: 0;
    }
  }

  &.active {
    svg {
      path.checked {
        opacity: 1;
      }
    }
  }
}

.conf__bottom-choose-val {
  color: #FFF;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border-bottom: 1px solid #fff;

}

.conf__bottom-choose-lbl {
  color: #FFF;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  margin-left: 0.8rem;

  @media screen and (max-width: $mb){
    margin-right: 0.8rem;
    text-align: right;
    font-size: 1.15rem;
  }
}

.conf__tb-body {

  @media screen and (max-width: $mb){
    padding-bottom: 3.6rem;

    &--pres {
      padding-bottom: 6rem;
    }
  }

  .conf__tb-row {
    background: #DBDBDB;
    height: 3.6rem;

    &.obj--booked {
      background-color: #F4D8D0;
    }

    &:not(:last-child){
      margin-bottom: 1rem;
    }

    @media screen and (max-width: $mb){
      flex-wrap: wrap;
      height: auto;
      padding: 1.6rem;
    }
  }
}

.conf__tb-td {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 300;
  color: #000;
}

.conf__form-it-lbl--indent {
  margin-top: 1.6rem;
}

.conf__tb-th {
  height: 2.4rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: -0.3px;
  font-weight: 300;
  color: #000;
}

.conf__bottom {
  height: 6.4rem;
  background: #2F3338;
  color: #fff;
  padding: 0 2rem;

  @media screen and (max-width: $mb){
    flex-wrap: wrap;
    height: auto;
    margin-left: 0;
    margin-right: 0;
    padding: 1.6rem 2rem 2rem;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    right: 0;
  }

  &-title {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.07rem;

    @media screen and (max-width: $mb){
      font-size: 1.15rem;
      letter-spacing: 0.06rem;
      text-align: left;
      margin-bottom: 0;
    }
  }
}

.sl__nav {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  cursor: pointer;
  z-index: 5;
  will-change: opacity;
  transition: 0.2s ease opacity;

  @media screen and (max-width: $mb){
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    left: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    top: -0.5rem;
  }

  &--disabled {
    opacity: 0.3 !important;
    cursor: default;
  }

  &:hover {
    opacity: 0.6;
  }

  @media screen and (min-width: $desk){
    &--prev{
      margin-right: 2.6rem;
      right: 100%;
    }

    &--next{
      margin-left: 2.6rem;
      left: 100%;
    }
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.sc__main-ct-box {
  &:not(:last-child){
    margin-bottom: 3.2rem;

    @media screen and (max-width: $mb){
      margin-bottom: 5.4rem;
    }
  }

  .row {
    margin-right: -1rem;
    margin-left: -1rem;
  }
}

.col {
  padding-left: 1rem;
  padding-right: 1rem;
}

.sc__title {
  color: #2F3338;
  font-size: 2.4rem;
  font-weight: 300;
  text-transform: uppercase;
}

.proj {
  &__sl {
    &-row {
      margin-left: -1rem;
      margin-right: -1rem;

      .proj__it {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }

    &-box {
      position: relative;

      @media screen and (min-width: $desk){
        .sl__nav {
          bottom: 20rem;
          margin-bottom: -1.2rem;
        }
      }
    }
  }

  &__it {
    &-img {
      img {
        display: block;
        width: 100%;
      }
    }

    &-hd {
      margin-bottom: 1.6rem;

      @media screen and (max-width: $mb){
        margin-bottom: 2.4rem;
      }
    }

    &-bl {
      position: relative;

      &:hover {
        .proj__it-ct {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &-ct {
      display: flex;
      flex-direction: column;
      padding: 4rem 3.2rem 2.4rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#fff, 0.75);
      opacity: 0;
      visibility: hidden;
      will-change: visibility, opacity;
      transition: 0.2s opacity ease, 0.2s visibility ease;

      &--disabled {
        &:after {
          content: '';
          background: url("../img/loader-dark.svg") no-repeat center;
          background-size: contain;
          width: 9.6rem;
          height: 9.6rem;
          left: 50%;
          top: 50%;
          position: absolute;
          margin-left: -4.8rem;
          margin-top: -4.8rem;
        }
      }
    }

    &-logo {
      height: 3.7rem;
      margin-bottom: 2.4rem;

      img {
        height: 100%;
        width: auto;
        display: block;
      }
    }

    &-descr {
      max-width: 22rem;
      color: #2F3338;
      font-size: 1.4rem;
      font-weight: 300;
      line-height: 140%;
    }

    &-bottom {
      color: #2F3338;
      font-size: 1.4rem;
      font-weight: 300;
      line-height: 140%;
      text-transform: uppercase;

      @media screen and (max-width: $mb){
        font-size: 1.4rem;
      }

      .btn {
        font-weight: 400;
      }

      a {
        color: #2F3338 !important;
        will-change: border;
        transition: 0.2s ease border;
        border-bottom: 1px solid #2F3338;

        &:hover {
          border-bottom: 1px solid transparent;
        }
      }
    }

    &-center {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;

      &::-webkit-scrollbar {
        width: 2px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #2F3338;
      }
    }

    &-nav {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          color: #2F3338;
          font-size: 1.4rem;
          font-weight: 300;
          line-height: 140%;
          text-transform: uppercase;

          &:not(:last-child){
            margin-bottom: 0.6rem;
          }

          a {
            cursor: pointer;
            color: #2F3338 !important;
            will-change: color;
            transition: 0.2s ease color;

            &:hover {
              color: #6A6A6A !important;
            }
          }
        }
      }
    }

    &-title {
      margin-bottom: 0.6rem;
      color: #2F3338;
      font-size: 2.4rem;
      font-weight: 300;
      text-transform: uppercase;

      @media screen and (max-width: $mb){
        font-size: 2rem;
      }
    }

    &-address {
      color: #2F3338;
      font-size: 1.4rem;
      font-weight: 300;
      text-transform: uppercase;

      @media screen and (max-width: $mb){
        font-size: 1.2rem;
      }
    }
  }
}

.conf__tb-empty {
  color: #000;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  padding: 2rem 0;
}

.icon__close {
  width: 2rem;
  min-width: 2rem;
  cursor: pointer;
  height: 2rem;
  margin-right: 0.8rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.input-pseudo {
  white-space: nowrap;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid #2F3338;
  opacity: 0;
}

.conf__form-init-val {
  cursor: pointer;
}

.conf__tb-th {
  cursor: pointer;

  span {
    user-select: none;
    white-space: nowrap;
  }
}

.icon__sort {
  width: 0.9rem;
  height: 0.5rem;
  min-width: 0.9rem;
  margin-left: 0.4rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.conf__form-input {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;

  input {
    display: block;
    background: transparent;
    border: none;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #2F3338;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
  }
}

@media screen and (min-width: 1024px){
  .keen-slider__slide {
    .docs__list {
      display: grid;
      grid-template:
            "a c e" 4rem
            "b d f" 4rem / 1fr 1fr 1fr;
      grid-row-gap: 1.6rem;
    }

    .docs__it {
      &:nth-child(1) {
        grid-area: a;
      }
      &:nth-child(2) {
        grid-area: b;
      }
      &:nth-child(3) {
        grid-area: c;
      }
      &:nth-child(4) {
        grid-area: d;
      }
      &:nth-child(5) {
        grid-area: e;
      }
      &:nth-child(6) {
        grid-area: f;
      }
    }
  }
}

@media screen and (max-width: 1023px){
  .keen-slider__slide {
    .docs__it {
      &:not(:last-child){
        margin-bottom: 2rem;
      }
    }
  }
}

.news {
  &__it {
    &-date {
      color: #6A6A6A;
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 0.9rem;

      span {
        border-bottom: 1px solid #6A6A6A;
      }
    }

    &-title {
      color: #6A6A6A;
      font-size: 1.4rem;
      font-weight: 300;
      text-transform: uppercase;

      @media screen and (max-width: $mb){
        font-size: 1.2rem;
        margin-bottom: 0.6rem;
      }
    }

    &-descr {
      color: #6A6A6A;
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 1;

      &:not(:last-child){
        margin-bottom: 1.6rem;

        @media screen and (max-width: $mb){
          margin-bottom: 0.9rem;
        }
      }

      & > * {
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }

    &-actions {
      color: #6A6A6A;
      font-size: 1.2rem;
      font-weight: 300;
      line-height: normal;
      text-transform: lowercase;

      a {
        cursor: pointer;
        color: #6A6A6A;
        will-change: border;
        transition: 0.2s border ease;
        border-bottom: 1px solid #6A6A6A;
        display: inline-block;

        &:hover {
          border-bottom-color: transparent;
        }
      }
    }
  }
}

.logo {
  position: absolute;
  top: 4rem;
  left: 4rem;
  width: 11.5rem;
  height: 2rem;

  @media screen and (max-width: $mb){
    width: 10.3rem;
    height: 1.8rem;
    left: 2rem;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.news__sl-box {
  position: relative;

  @media screen and (min-width: $desk){
    .sl__nav {
      top: 50%;
      margin-top: -0.9rem;
    }
  }

  &[data-count="1"] {
    .sl__nav {
      display: none !important;
    }
  }
}

.box--docs {
  .sc__title {
    margin-bottom: 2.5rem;

    @media screen and (max-width: $mb){
      margin-bottom: 2.2rem;
    }
  }
}

@media screen and (min-width: $desk){
  .proj__sl-box {
    &[data-count="1"],
    &[data-count="2"],
    &[data-count="3"] {
      .sl__nav {
        display: none !important;
      }
    }
  }
}

.wrapper {
  overflow: hidden;
}

.docs {
  &__list {
    .row {
      margin-bottom: -2.4rem;
      margin-right: -2rem;
      margin-left: -2rem;

      @media screen and (max-width: $mb){
        margin-bottom: -1.9rem;
      }
    }

    .col {
      margin-bottom: 2.4rem;
      padding-left: 2rem;
      padding-right: 2rem;

      @media screen and (max-width: $mb){
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1.9rem;
      }
    }
  }

  &__it {
    &-title {
      margin-bottom: 0.4rem;
      color: #6A6A6A;
      font-size: 1.4rem;
      font-weight: 300;
      line-height: 140%;
      text-transform: uppercase;

      a {
        color: #6A6A6A !important;
        will-change: opacity;
        transition: 0.2s opacity ease;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    &-download {
      color: #6A6A6A;
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;

      a {
        color: #6A6A6A !important;
        border-bottom: 1px solid #6A6A6A;
        will-change: border;
        transition: 0.2s border ease;

        &:hover {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}

.reg__box {
  width: 70rem;
  min-height: 75rem;
  padding: 8rem 9rem 4.5rem;
  background: #DBDBDB;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 20;
  will-change: transform;
  transition: 0.45s transform ease;
  transform: translateX(-100%) translateY(100%);

  @media screen and (max-width: $mb){
    width: 35.5rem;
    padding: 3rem 2.6rem 4rem;
    min-height: 0;
  }

  &.active{
    transform: translateX(0) translateY(0);
  }

  &-title {
    color: #2F3338;
    font-size: 2.4rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 2.4rem;

    @media screen and (max-width: $mb){
      margin-bottom: 1.6rem;
    }
  }

  &-close {
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
    top: 4rem;
    right: 4.7rem;
    z-index: 5;
    position: absolute;
    will-change: opacity;
    transition: 0.2s opacity ease;

    @media screen and (max-width: $mb){
      width: 1.6rem;
      height: 1.5rem;
      right: 0.8rem;
      top: 1.2rem;
    }

    &:hover {
      opacity: 0.75;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.form__field--select {
  position: relative;

  &.active {
    .select__dropdown {
      opacity: 1;
      visibility: visible;
    }
  }
}

.select__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: #DBDBDB;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 5;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.select__toggle {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  z-index: 10;
}

.select__it {
  cursor: pointer;
  padding: 0.3rem 1rem;

  &:not(:last-child){
    margin-bottom: 0.5rem;
  }

  &:hover, &.active {
    color: #A44F29;
  }
}

.reg__form {
  .form__it-lbl {
    min-width: 10.2rem;
    width: 10.2rem;
    color: #2F3338;
    font-size: 1.6rem;
    font-weight: 300;
    text-transform: uppercase;

    @media screen and (max-width: $mb){
      font-size: 1.2rem;
      min-width: 8.2rem;
      width: 8.2rem;
    }
  }

  .error__message {
    position: absolute;
    right: 0;
    top: 100%;
  }

  .form__it {
    position: relative;
  }

  .form__field {
    width: 100%;

    input {
      background-color: transparent;
      width: 100%;
      border-bottom-color: #6A6A6A;
    }

    &.has-error {
      input {
        border-bottom-color: #A44F29;
      }
    }
  }

  .checkbox__wrap {
    margin-left: 6rem;

    @media screen and (max-width: $mb){
      margin-left: 0;
    }
  }

  .checkbox__title  {
    font-size: 1.6rem;
    line-height: 140%;
    padding-top: 0.1rem;
    color: #6A6A6A;

    @media screen and (max-width: $mb){
      font-size: 1.2rem;
      padding-top: 0.3rem;
    }
  }

  .form__it--indent {
    margin-top: 4.8rem;

    @media screen and (max-width: $mb){
      margin-top: 1.6rem;
    }
  }

  .form__it--actions {
    margin-top: 2.6rem;

    @media screen and (max-width: $mb){
      margin-top: 1.6rem;
    }
  }

  .checkbox__wrap {
    position: relative;

    .error__message {
      position: relative;
      right: 0;
      top: auto;
    }

    &.has-error {
      .checkbox__decor {
        border-color: #A44F29;
      }
    }
  }
}

.docs__sl-box {
  .keen-slider__slide {
    padding-bottom: 0.2rem;
  }
}

.faq__it-icon {
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  margin-right: 2.4rem;
  cursor: pointer;
  will-change: opacity;
  transition: opacity 0.2s ease;

  @media screen and (max-width: $mb){
    width: 1.6rem;
    height: 1.6rem;
    min-width: 1.6rem;
    opacity: 1 !important;
    margin-right: 1.4rem;
  }

  &:hover {
    opacity: 0.75;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.faq__it-title {
  color: #FFF;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 0.4rem;
  cursor: pointer;
  will-change: opacity;
  transition: opacity 0.2s ease;

  @media screen and (max-width: $mb){
    font-size: 1.4rem;
    margin-top: 0.2rem;
  }

  &:hover {
    opacity: 0.75;
  }
}

.faq__it-text {
  margin-top: 1.6rem;
  color: #F2F2F2;
  font-size: 1.6rem;
  font-weight: 300;

  @media screen and (max-width: $mb){
    font-size: 1.2rem;
    margin-top: 1.2rem;
  }

  a {
    color: #DFDFDF !important;
    border-bottom: 1px solid #DFDFDF;
    text-transform: uppercase;
    font-size: 1rem;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }
}

.faq__it {
  &:not(.active){
    .faq__it-answ {
      display: none;
    }
  }
  &-ct {
    width: 100%;
    padding-bottom: 2.6rem;
    border-bottom: 1px solid #fff;

    @media screen and (max-width: $mb){
      padding-bottom: 2rem;
    }
  }

  &:not(:last-child){
    margin-bottom: 3rem;
  }

  &.active {
    .faq__it-icon {
      path {
        &:last-child {
          opacity: 0;
        }
      }
    }
  }
}

@media screen and (min-width: $desk){
  .faq__box-scroll {
    margin-right: -3rem;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 36rem;

    &::-webkit-scrollbar {
      width: 0.2rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(#B2B2B2, 0.1);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #B2B2B2;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #B2B2B2;
    }
  }

  .faq__box-list {
    padding-right: 3rem;
  }
}

.recovery__link {
  margin-top: 1rem;


  a {
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 1.6rem;

    @media screen and (max-width: $mb){
      font-size: 1.4rem;
    }

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.docs__it-title {
  a {
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.wrapper {
  /*max-width: 144rem;
  margin-left: auto;
  margin-right: auto;*/
}

.dot {
  width: 1px;
  position: relative;
  height: 1.4rem;
  border: none;
  background: none;

  &:not(:last-child){
    margin-right: 1.4rem;
  }

  &:before {
    content: '';
    position: absolute;
    height: 1rem;
    bottom: 0;
    background: #B2B2B2;
    left: 0;
    width: 100%;
  }

  &.active {
    &:before {
      height: 100%;
      background: #000;
    }
  }
}

.conf__wrap {
  display: flex;
  height: 100svh;
  flex-direction: column;

  @media screen and (max-width: $mb){
    height: auto;
  }
}

.conf__form-it--adds {
  padding-top: 1.5rem;

  .conf__form-it-lbl {
    text-transform: none;
    margin-bottom: 0.8rem;
  }
}

.conf__form-it--clear {
  margin-bottom: 1rem !important;

  .btn {
    height: 3.6rem;
    font-size: 1.6rem;
    padding-top: 0.7rem;

    &[disabled] {
      cursor: default;
      border: 1px solid #B2B2B2;
      color: #B2B2B2;
    }
  }
}

.conf__form-it-bl {
  position: relative;
  padding-bottom: 1.6rem;
}

.filter__adds {
  column-gap: 2rem;
  row-gap: 0.6rem;
}

.filter__adds-it {
  color: #B2B2B2;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  text-transform: uppercase;

  &.active {
    color: #A44F29;
  }

  &:not(:last-child){
    //margin-right: 3rem;
  }
}

.conf__form-init {
  position: absolute;
  left: 0;
  width: 2rem;
  top: 100%;
  margin-top: -0.2rem;

  &--right {
    left: auto;
    right: 0;
  }

  &-val {
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    color: #2F3338;
    text-align: center;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    border-bottom: 1px solid #2F3338;
  }
}

.form__it-hint {
  color: #000;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: normal;
  margin-top: 1rem;

  b {
    font-weight: 500;
  }

  span {
    color: #A44F29;
    font-size: 1.6rem;
    font-weight: 300;
    padding-right: 0.4rem;
    line-height: normal;
    text-transform: lowercase;
  }
}

svg {
  overflow: visible !important;
}

@media screen and (min-aspect-ratio: 16/10) and (min-width: 1024px){
  html {
    font-size: 1.1111vh;
  }
}

@media screen and (max-aspect-ratio: 16/10) and (min-width: 1024px){
  html {
    font-size: 0.69444vw;
  }
}

@media screen and (min-width: 1024px){
  .mb-only {
    display: none !important;
  }
}

@media screen and (max-width: 1023px){
  html {
    font-size: 2.666667vw;
  }

  .select__it {
    font-size: 1.4rem;
  }

  .desk-only {
    display: none !important;
  }

  .sl__nav-box {
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;
    margin-top: 3.5rem;
  }

  .dots {
    margin-right: 3.2rem;
    margin-left: 3.2rem;
  }

  .sc__box-left,
  .sc__box-right {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sc__box-left {
    margin-bottom: 2.2rem;
  }

  .sc__title {
    font-size: 2rem;
  }

  .news__it-left, .news__it-right {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sc__main-footer {
    background: #D9D9D9;
  }

  .sc__main-left-tabs {
    padding-top: 8.8rem;
    padding-bottom: 3rem;
  }

  .sc__main-left {
    padding-top: 0;

    .logo {
      position: relative;
      z-index: 50;
    }

    .nav__bars {
      z-index: 50;
    }
  }

  .back__link {
    font-size: 1.4rem;
    border-bottom: 1px solid rgba(#000, 0.5);
    cursor: pointer;
    margin-top: 2rem;
  }

  .sc__conf {
    .sc__main-left-content {
      position: relative;
      padding-top: 9rem;
      padding-bottom: 5.4rem;
      overflow: hidden;
      padding-right: 6rem;
      margin-bottom: 3rem;
    }

    .sc__main-left {
      padding-left: 2rem;
      display: block !important;
      position: relative;
      overflow: hidden;

      .logo {
        position: absolute;
        left: 4rem;
        top: 4rem;
      }
    }
  }

  .conf__tb-title {
    color: #000;
    font-size: 1.4rem;
    margin-bottom: 2.2rem;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
  }
}